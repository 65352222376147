import React, { useEffect, useRef, useState } from 'react';
import './ExamsList.scss';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { useParams } from 'react-router-dom';
import { useUserState } from '../../services/User';
import { addDoc, collection, doc, onSnapshot, orderBy, query, where, writeBatch } from 'firebase/firestore';
import { db, useAuthState } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import { useStaticState } from '../../services/StaticContext';
import { Exam } from '../../models/exam';
import { ExamType } from '../../models/exam-type';
import { WidgetBox } from '../../components/widget-box/WidgetBox';
import { ExamResult } from '../../models/exam-result';
import { Question } from '../../models/question';
import { Utils } from '../../util/string-utils';
import { TableWidget } from '../../components/table-widget/TableWidget';
import { useLoader } from '../../services/LoaderProvider';
import { SuccessModal } from '../../components/success-modal/SuccessModal';
import axios from 'axios';
import { isWithinInterval, addYears } from 'date-fns';
import CSVReader from 'react-csv-reader';
import Skeleton from 'react-loading-skeleton';
import { TableWidgetFace } from '../../components/table-widget/TableWidgetFace';
import { ModalPayment } from '../../components/modal-payment/ModalPayment';

const ExamsList = () => {
  let user = useUserState();
  const { user: authUser, loading: isLoading } = useAuthState();
  const { startLoader, stopLoader } = useLoader();
  let { payment }: any = useParams();
  const successModalRef: any = useRef(null);
  const modalPaymentRef: any = useRef(null);

  const { examTypes } = useStaticState();
  const [examList, setExamList] = useState<Exam[]>();
  const [faceTofaceExams, setFaceTofaceExams] = useState<Exam[]>();
  const [boughtExams, setBoughtExams] = useState<string[]>([]);
  const [successModalLoading, setSuccessModalLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedType, setSelectedType] = useState<any>('Wa1XNOCHd6OqZ29M9K4D');

  useEffect(() => {
    if (user?.id) {
      setTimeout(() => {
        // THIS CANNOT BE REMOVED!!
        const subscriptionIntentItem = sessionStorage.getItem('subscriptionIntent');
        if (subscriptionIntentItem) {
          sessionStorage.removeItem('subscriptionIntent');
       /*    startLoader();
          Utils.handleCheckout(subscriptionIntentItem, user); */
          modalPaymentRef.current.handleShow();
        }
      }, 3600);
    }
  }, [user]);

  useEffect(() => {
    if (authUser?.uid) {
      //startLoader(); // SINCE THE EXAMSLIST IS THE DEFAULT PAGE NOW, THE USER GETS REDIRECTED TO THE LANDING PAGE IF NOT LOGGED IN, SO THE LOADER HAS TO START ONLY IF THERE IS A USER
      setLoading(true);
      getExamsFromDb();

      setTimeout(() => {
        getAllResults();
      }, 3000);
    }
  }, [authUser?.uid]);

  useEffect(() => {
    if (user?.id && examTypes) {
      if (payment === 'success') {
        setSuccessModalLoading(true);
        setTimeout(() => {
          setSuccessModalLoading(false);
        }, 60000);
        successModalRef.current.handleShow();
        listenToUserChanges();
      } else {
        console.log('querying stripe api');
        queryStripeApi(user);
      }
    }
  }, [user?.id, examTypes]);

  function listenToUserChanges() {
    const q = query(collection(db, 'user'), where('id', '==', user?.id));
    onSnapshot(q, (querySnapshot) => {
      const userRT = querySnapshot.docs[0].data();

      if (userRT?.checkoutSessions) {
        queryStripeApi({ ...userRT });
        user = { ...userRT };
      }
    });
  }

  async function queryStripeApi(user: any) {
    if (!user?.checkoutSessions) {
      return;
    }

    try {
      const respCheckouts: any = await axios.post(process.env.REACT_APP_PUBLIC_PAYMENTS_FUNCTION_URL!, {
        payments: user?.checkoutSessions,
        isProd: process.env.REACT_APP_PUBLIC_PROD === 'true',
      });

      console.log(respCheckouts);
      const lastBoughtExam = window.localStorage.getItem('lastBoughtExam');

      respCheckouts?.data?.forEach((data: any) => {
        /* const checkoutSession = data?.data[0]?.price;

        const createdAt = new Date(checkoutSession?.created * 1000);
        const oneYearFromPurchase = addYears(createdAt, 1);

        const isWithinOneYear = isWithinInterval(new Date(), {
          start: createdAt,
          end: oneYearFromPurchase,
        }); */

        const plan = data?.plan;

        console.log(plan?.product)

        if (plan?.active) {
          if (plan.product === process.env.REACT_APP_PRODUCT_GL_ASSESSMENT_PROD_MONTHLY || plan.product === process.env.REACT_APP_PRODUCT_GL_ASSESSMENT_PROD_YEARLY) {
            setBoughtExams((prevState) => [...prevState, 'Wa1XNOCHd6OqZ29M9K4D']);
          }
          if (plan.product === process.env.REACT_APP_PRODUCT_SUTTON_PROD_MONTHLY || plan.product === process.env.REACT_APP_PRODUCT_SUTTON_PROD_YEARLY) {
            setBoughtExams((prevState) => [...prevState, 'GiXgZHoJfIwEY1ID2vrn']);
          }
          if (plan.product === process.env.REACT_APP_PRODUCT_TIFFIN_PROD_MONTHLY || plan.product === process.env.REACT_APP_PRODUCT_TIFFIN_PROD_YEARLY) {
            setBoughtExams((prevState) => [...prevState, 'cDaUveEJwqt084KzaCkI']);
          }
          if (plan.product === process.env.REACT_APP_PRODUCT_COMPLETE_PACKAGE_PROD_MONTHLY || plan.product === process.env.REACT_APP_PRODUCT_COMPLETE_PACKAGE_PROD_YEARLY) {
            setBoughtExams((prevState) => [...prevState, 'all']);
          }

          if (plan.product === lastBoughtExam) {
            setSuccessModalLoading(false);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function getExamsFromDb() {
    const customQuery = query(
      collection(db, 'exam'),
      where('deleted', '==', false),
      orderBy('isPaid', 'asc'),
      orderBy('difficulty', 'asc'),
      orderBy('order', 'asc')
    );
    /* const customQuery = query(collection(db, 'exam'),
    and(where('deleted', '==', false),
      or(where('isOnline', '==', true),
         where('expectedDate', '<', new Date())
      )
    ), orderBy('expectedDate', 'asc')
    ); */
    const exams = await QueryUtils.executeQuery(customQuery);
    console.log(exams?.filter((e: Exam) => e.type === '7yL8rJSN8Drr01fBtHDa'));
    //setExamList(exams as Exam[]);
    //console.log(exams)

    getResultsFromDb(
      exams.filter((e: Exam) => {
        return e.isOnline || e.expectedDate;
      }) as Exam[]
    );
  }

  async function getAllResults() {
    //VERY COSTLY
    /*  const customQuery = query(collection(db, 'results'));
    const results = (await QueryUtils.executeQuery(customQuery)) as ExamResult[];
    setFirstResults(results); */
  }

  async function getResultsFromDb(exams: Exam[]) {
    const customQuery = query(collection(db, 'results'), where('userId', '==', authUser.uid), orderBy('created_date', 'desc'));
    const results = (await QueryUtils.executeQuery(customQuery)) as ExamResult[];

    const customQuery2 = query(
      collection(db, 'additional-results'),
      where('userId', '==', authUser.uid),
      orderBy('created_date', 'desc')
    );
    const results2 = (await QueryUtils.executeQuery(customQuery2)) as ExamResult[];

    const userResults = results2.concat(results);

    exams.forEach((exam) => {
      const examResults = userResults
        .filter((r) => r.examId === exam.id && !Utils.isFaceToFace(exam))
        .sort((a, b) => (a.created_date < b.created_date ? 1 : -1));
      if (examResults?.[0]?.answers?.length) {
        if (Utils.isExamOnGoing(exam, examResults[0])) {
          exam.onGoing = true;
        } else {
          exam.doneOnce = true;
        }

        if (examResults.length > 1 || !exam.onGoing) {
          exam.score = calcScore(exam.questions, examResults[examResults.length - 1]) + '/' + exam.questions?.length;
        }
      } else {
        exam.noResults = true;
      }
      exam.retries = examResults?.length ? examResults.length - 1 : 0;
      if (exam.onGoing && exam.retries > 0) exam.retries--;
    });

    setExamList(exams);

    //stopLoader();
    setLoading(false);
  }

  function onSelectPlan(linkUrl: string) {
    console.log(linkUrl);
    modalPaymentRef.current.handleHide();
    Utils.handleCheckoutSimple(linkUrl, user);
  }

  async function loadF2FResults() {
    const customQuery3 = query(collection(db, 'results-face-to-face')); //where('surname', '==', user?.lastName), where('forename', '==', user?.firstName)
    let results3 = (await QueryUtils.executeQuery(customQuery3)) as ExamResult[];
    results3 = results3.map((r) => Utils.convertFaceToFace(r));
    console.log(results3);

    const filteredResults = results3.filter(
      (r: any) =>
        r?.lastName?.toLowerCase() === user?.lastName.toLowerCase() &&
        r?.firstName?.toLowerCase() === user?.firstName?.toLowerCase()
    );

    const f2fExams = examList?.filter((e: Exam) => e.type === '7yL8rJSN8Drr01fBtHDa');
    const mappedF2fExams = f2fExams?.map((e: Exam) => {
      if (filteredResults.some((r: any) => r.examId === e.id)) {
        e.noResults = false;
      }

      return e;
    });

    setFaceTofaceExams(mappedF2fExams?.map((e: Exam) => {
      const found = filteredResults.find((r: any) => r.examId === e.id);
      if (found) {
        e.score = calcScore(e.questions, found) + '/' + e.questions?.length;
      }
      return e;
    })
    );
  }

  function calcScore(questions: Question[], examResult: ExamResult) {
    let score = 0;
    questions.forEach((question, i) => {
      const found = examResult.answers?.[0]?.questionId ? examResult.answers?.find((answer) => answer.questionId === question.id) : examResult.answers?.[i];
      if (found && found.markedOptions?.length && found.markedOptions?.length === question.correctOptions?.length) {
        if (question.correctOptions?.length) {
          let anyWrong = true;
          question.correctOptions.forEach((correctOption) => {
            const right = found.markedOptions.some((m) => m === correctOption);
            if (!right) anyWrong = false;
          });

          if (anyWrong) score++;
        }
      }
    });

    return score;
  }

  function updateSelectedType(examId: string) {
    setSelectedType(examId);
    if (Utils.isFaceToFace({ type: examId })) {
      loadF2FResults();
    }
  }

  return (
    <ProtectedPages>
      <section id="examsList">
        <div className="data-box">
          <div className="performance-container">
            <WidgetBox
              title="Summary"
              subtitle=""
              text1={'Completed Tests: ' + '1500+'}
              text2={'Tests Available: ' + (examList?.length || '...')}
            />
          </div>
        </div>

        {/*  {examTypes
          ?.filter((type) => examList?.some((ex) => ex.type === type.id))
          ?.map((type: ExamType, i: any) => (
            <TableWidget unlocked={boughtExams?.some((item) => item === type.id || item === 'all')} key={type.id} exams={examList?.filter((e) => e.type === type.id)} examType={type} />
          ))} */}
        <div className="third-section box-shadow">
          <div className="free-test-container-header">
            {examTypes?.map(
              (
                type: ExamType,
                i: any //?.filter(type => type.id !== '7yL8rJSN8Drr01fBtHDa')
              ) => (
                <div
                  key={i}
                  className={'type-exams' + (selectedType === type.id ? ' selected' : '')}
                  onClick={() => updateSelectedType(type.id)}
                >
                  {type.id === 'Wa1XNOCHd6OqZ29M9K4D' && <img className="exam-icons" src="/icons/gla-logo.png" />}
                  {type.id === 'cDaUveEJwqt084KzaCkI' && (
                    <div className="d-flex">
                      <img className="exam-icons" src="/icons/tiffin-girls.jpg" />
                      <img className="exam-icons" src="/icons/tiffin-boys.png" />
                    </div>
                  )}
                  {type.id === 'GiXgZHoJfIwEY1ID2vrn' && <img className="exam-icons" src="/icons/sutton-logo.png" />}
                  {type.id === '7yL8rJSN8Drr01fBtHDa' && <img className="exam-icons" src="/icons/logo_table.svg" />}
                  <span className="exam-name-text">{type.name}</span>
                </div>
              )
            )}
            {/*  {user?.role == 1 && (
              <div className={'type-exams' + (selectedType === 'face' ? ' selected' : '')} onClick={() => setSelectedType('face')}>
                <img className="exam-icons" src="/icons/logo_table.svg" />
                <span className="exam-name-text">Face To Face</span>
              </div>
            )}  */}
          </div>

          <div className="free-test-container-body">
            {loading ? (
              <div className="skeleton-loader">
                <Skeleton height={68} count={10} />
              </div>
            ) : (
              <>
                {examTypes
                  ?.filter((type) => type.id === selectedType && type.id !== '7yL8rJSN8Drr01fBtHDa')
                  ?.map((type: ExamType, i: any) => (
                    <div key={type.id}>
                      <TableWidget
                        unlocked={boughtExams?.some((item) => item === type.id || item === 'all')}
                        key={type.id}
                        exams={examList?.filter((e) => e.type === type.id)}
                        examType={type}
                      />
                    </div>
                  ))}
                {selectedType === '7yL8rJSN8Drr01fBtHDa' && (
                  <div>
                    <TableWidget
                      unlocked={true}
                      key={'face-to-f-table'}
                      exams={faceTofaceExams}
                      examType={Utils.faceToFaceExamObj()}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>

      <SuccessModal
        loading={successModalLoading}
        onCancel={() => setTimeout(() => stopLoader(), 1200)}
        ref={successModalRef}
      ></SuccessModal>

      <ModalPayment
        onConfirm={(linkUrl: string) => onSelectPlan(linkUrl)}
        description={<span>Complete your purchase to get access to all our premium exams now!</span>}
        ref={modalPaymentRef}
        title="payment"
      ></ModalPayment>
    </ProtectedPages>
  );
};

export default ExamsList;
